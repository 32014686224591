import { getConfig } from "@src/config";
import { GQLFinanceType, GQLVehicleDetailQuery } from "@src/graphql.apollo.generated";
import { ButtonSize, ButtonVariant } from "@src/shared/button/Button";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import { SnackbarType } from "@src/shared/context/snackbar/SnackbarContext";
import useSnackbar from "@src/shared/context/snackbar/useSnackbar";
import pushToDataLayer from "@src/shared/tracking/pushToDataLayer";
import { postContactDealerForm } from "@src/shared/utils/httpClient";
import { checkEmailValidation } from "@src/shared/utils/validations";
import { getCountryCodeFromScope, getLanguagefromDomain } from "@src/skeleton/intlProvider/CustomIntlProvider";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";

import * as sc from "./ContactForm.sc";
import { getContactMessage, getCopyMailText } from "./getContactMessage";

const domain = getConfig("scope").domain;
const contactForm = JSON.parse(getConfig("contactForm"))[domain];

const privacyPolicyLinks: { [key: string]: string } = {
    "car4me.bg": "https://www.porscheleasing.bg/privacy-policy",
    "lease-me.porschebank.at": "https://www.porschebank.at/datenschutzdetailanker",
    "car4me.si": "https://www.porscheleasing.si/podjetje/varstvo-osebnih-podatkov/",
    "car4me.porschefinance.hu": "https://www.porschefinance.hu/adatvedelem/",
};

interface IContactFormProps {
    handleClose: () => void;
    vehicle: GQLVehicleDetailQuery["vehicle"];
    visibleTab: GQLFinanceType;
    refProp: any;
}

export interface IFormValues {
    message: string;
    firstname: string;
    lastname: string;
    email: string;
    dsgvo: boolean;
    phone: string;
    residence: string;
    secondname?: string;
    contactTime?: string;
    financeOffer?: boolean;
    changeCar?: boolean;
    city?: string;
    street?: string;
    zip?: string;
}

const ContactForm: React.FunctionComponent<IContactFormProps> = ({ handleClose, vehicle, refProp }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const { showSnackbar } = useSnackbar();
    const intl = useIntl();
    const { filter } = useSearchFilter();

    async function onSubmit(values: IFormValues) {
        pushToDataLayer({
            event: "dealer-contact-form-submit",
        });

        setIsLoading(true);

        const contactMessage = getContactMessage(values, vehicle, intl);

        let firstname = values.firstname;
        if (values.secondname) firstname += ` ${values.secondname}`;

        const contactData = {
            message: contactMessage,
            firstname,
            lastname: values.lastname,
            email: values.email,
            phone: values.phone,
            residence: values.residence,
            offer_reference: vehicle.offerReference,
            tracking_id: Math.floor(Math.random() * 10000),
            language: getLanguagefromDomain(getConfig("scope").domain),
            reachability: values.contactTime,
            scope: getConfig("scope"),
            country: getCountryCodeFromScope(),
            copySubject: intl.formatMessage({
                id: "leaseme.contactManager.copySubject",
                defaultMessage: "Anfrage auf lease-me.at (V2)",
            }),
            copyMailText: getCopyMailText(values, vehicle, intl),
            bnr: vehicle.dealer?.bnr,
            city: values.city,
            street: values.street,
            zip: values.zip,
            filter,
            vehicleId: vehicle.vehicleId,
        };

        const response = await postContactDealerForm(contactData);

        if (response.status !== 201) {
            showSnackbar(
                SnackbarType.error,
                intl.formatMessage({
                    id: "leaseme.contactForm.send.error",
                    defaultMessage: "Fehler! Ihre Nachricht konnte nicht gesendet werden...",
                }),
            );
        } else {
            showSnackbar(
                SnackbarType.success,
                intl.formatMessage({
                    id: "leaseme.contactForm.send.success",
                    defaultMessage: "Ihre Nachricht wurde erfolgreich versendet!",
                }),
            );
            handleClose();
        }
        setIsLoading(false);
    }

    React.useEffect(() => {
        refProp.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }, [refProp]);

    const requiredValidate = (value: string | boolean): string | undefined => {
        if (!value) {
            return "REQUIRED";
        }
    };

    const emailValidation = (value: string): any => {
        if (!checkEmailValidation(value)) {
            return (
                <sc.ErrorMessage>
                    {intl.formatMessage({
                        id: "leaseme.contactForm.email.error",
                        defaultMessage: "Keine gültige E-Mail-Adresse!",
                    })}
                </sc.ErrorMessage>
            );
        }
    };

    const composeValidators =
        (...validators: any[]) =>
        (value: any) => {
            return validators.reduce((error, validator) => error || validator(value), undefined);
        };

    const Error = ({ name }: { name: string }) => (
        <Field name={name} subscription={{ error: true }}>
            {({ meta: { error } }) => (error && error !== "REQUIRED" ? error : null)}
        </Field>
    );

    return (
        <sc.ContactForm ref={refProp}>
            <sc.IconContainer>
                <sc.ContactFormHeadline>
                    <FormattedMessage id="leaseme.dealer.contact" defaultMessage="Händler kontaktieren" />
                </sc.ContactFormHeadline>
                <sc.CustomCloseIcon onClick={handleClose} />
            </sc.IconContainer>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, valid }) => {
                    return (
                        <sc.StyledForm>
                            <sc.FieldsContainer>
                                <sc.PersonInfoContainer>
                                    {getConfig("scope").domain === "car4me.porschefinance.hu" && (
                                        <sc.LabelFieldContainer>
                                            <sc.Label>
                                                <FormattedMessage id="leaseme.contactForm.lastname" defaultMessage="Nachname" />
                                                {" *"}
                                            </sc.Label>
                                            <sc.StyledInputField
                                                name="lastname"
                                                component="input"
                                                autoComplete="family-name"
                                                placeholder={intl.formatMessage({
                                                    id: "leaseme.contactForm.placeholder.familyName",
                                                    defaultMessage: "Mustermann",
                                                })}
                                                validate={requiredValidate}
                                            />
                                        </sc.LabelFieldContainer>
                                    )}
                                    <sc.RowContainer>
                                        <sc.LabelFieldContainer>
                                            <sc.Label>
                                                <FormattedMessage id="leaseme.contactForm.firstname" defaultMessage="Vorname" />
                                                {" *"}
                                            </sc.Label>
                                            <sc.StyledInputField
                                                name="firstname"
                                                component="input"
                                                autoComplete="on"
                                                placeholder={intl.formatMessage({
                                                    id: "leaseme.contactForm.placeholder.firstName",
                                                    defaultMessage: "Max",
                                                })}
                                                validate={requiredValidate}
                                            />
                                        </sc.LabelFieldContainer>

                                        {contactForm.hasSecondName && (
                                            <sc.LabelFieldContainer>
                                                <sc.Label>
                                                    <FormattedMessage id="leaseme.contactForm.secondname" defaultMessage="Zweitname" />
                                                </sc.Label>
                                                <sc.StyledInputField
                                                    name="secondname"
                                                    component="input"
                                                    autoComplete="secondname"
                                                    placeholder={intl.formatMessage({
                                                        id: "leaseme.contactForm.placeholder.secondname",
                                                        defaultMessage: "Maxi",
                                                    })}
                                                />
                                            </sc.LabelFieldContainer>
                                        )}
                                        {getConfig("scope").domain !== "car4me.porschefinance.hu" && (
                                            <sc.LabelFieldContainer>
                                                <sc.Label>
                                                    <FormattedMessage id="leaseme.contactForm.lastname" defaultMessage="Nachname" />
                                                    {" *"}
                                                </sc.Label>
                                                <sc.StyledInputField
                                                    name="lastname"
                                                    component="input"
                                                    autoComplete="family-name"
                                                    placeholder={intl.formatMessage({
                                                        id: "leaseme.contactForm.placeholder.familyName",
                                                        defaultMessage: "Mustermann",
                                                    })}
                                                    validate={requiredValidate}
                                                />
                                            </sc.LabelFieldContainer>
                                        )}
                                    </sc.RowContainer>

                                    <sc.RowContainer>
                                        <sc.LabelFieldContainer>
                                            <sc.Label>
                                                <FormattedMessage id="leaseme.contactForm.email" defaultMessage="E-Mail" />
                                                {" *"}
                                                <Error name="email" />
                                            </sc.Label>
                                            <sc.StyledInputField
                                                name="email"
                                                component="input"
                                                type="email"
                                                autoComplete="email"
                                                validate={composeValidators(requiredValidate, emailValidation)}
                                                placeholder={intl.formatMessage({
                                                    id: "leaseme.contactForm.placeholder.email",
                                                    defaultMessage: "max.mustermann@beispielmail.at",
                                                })}
                                            />
                                        </sc.LabelFieldContainer>

                                        {contactForm.hasPhoneNumber && (
                                            <sc.LabelFieldContainer>
                                                <sc.Label>
                                                    <FormattedMessage id="leaseme.contactForm.phone" defaultMessage="Telefonnummer" />
                                                    {" *"}
                                                </sc.Label>
                                                <sc.StyledInputField
                                                    name="phone"
                                                    component="input"
                                                    type="tel"
                                                    autoComplete="phone"
                                                    placeholder={intl.formatMessage({
                                                        id: "leaseme.contactForm.placeholder.phone",
                                                        defaultMessage: "+43 123 4567890",
                                                    })}
                                                    validate={requiredValidate}
                                                />
                                            </sc.LabelFieldContainer>
                                        )}
                                    </sc.RowContainer>

                                    {contactForm.hasAddress && (
                                        <sc.RowContainer>
                                            <sc.LabelFieldContainer>
                                                <sc.Label>
                                                    <FormattedMessage id="leaseme.contactForm.address" defaultMessage="Adresse" />
                                                </sc.Label>
                                                <sc.StyledInputField name="street" component="input" autoComplete="street" />
                                            </sc.LabelFieldContainer>
                                            <sc.LabelFieldContainer>
                                                <sc.Label>
                                                    <FormattedMessage id="leaseme.contactForm.zip" defaultMessage="PLZ" />
                                                </sc.Label>
                                                <sc.StyledInputField name="zip" component="input" autoComplete="zip" />
                                            </sc.LabelFieldContainer>
                                            <sc.LabelFieldContainer>
                                                <sc.Label>
                                                    <FormattedMessage id="leaseme.contactForm.city" defaultMessage="Ort" />
                                                </sc.Label>
                                                <sc.StyledInputField name="city" component="input" autoComplete="city" />
                                            </sc.LabelFieldContainer>
                                        </sc.RowContainer>
                                    )}

                                    {contactForm.hasResidenceAndContactHour && (
                                        <sc.RowContainer>
                                            <sc.LabelFieldContainer>
                                                <sc.Label>
                                                    <FormattedMessage id="leaseme.contactForm.residence" defaultMessage="Wohnsitz" />
                                                    {" *"}
                                                </sc.Label>
                                                <sc.StyledInputField
                                                    name="residence"
                                                    component="input"
                                                    autoComplete="residence"
                                                    placeholder={intl.formatMessage({
                                                        id: "leaseme.contactForm.placeholder.residence",
                                                        defaultMessage: "Salzburg",
                                                    })}
                                                    validate={requiredValidate}
                                                />
                                            </sc.LabelFieldContainer>
                                            <sc.LabelFieldContainer>
                                                <sc.Label>
                                                    <FormattedMessage
                                                        id="leaseme.contactForm.contactTime"
                                                        defaultMessage="Contact me between these hours"
                                                    />
                                                </sc.Label>
                                                <sc.StyledInputField
                                                    name="contactTime"
                                                    component="input"
                                                    autoComplete="contactTime"
                                                    placeholder={intl.formatMessage({
                                                        id: "leaseme.contactForm.placeholder.contactTime",
                                                        defaultMessage: "ab 14 Uhr",
                                                    })}
                                                />
                                            </sc.LabelFieldContainer>
                                        </sc.RowContainer>
                                    )}
                                </sc.PersonInfoContainer>
                                <sc.MessageContainer>
                                    <sc.MessageFieldLabelContainer>
                                        <sc.Label>
                                            <FormattedMessage id="leaseme.contactForm.message" defaultMessage="Nachricht" />
                                            {" *"}
                                        </sc.Label>
                                        <sc.StyledTextArea
                                            name="message"
                                            component="textarea"
                                            placeholder={intl.formatMessage({
                                                id: "leaseme.contactForm.placeholder.message",
                                                defaultMessage: "Ihre Nachricht...",
                                            })}
                                            validate={requiredValidate}
                                        />
                                    </sc.MessageFieldLabelContainer>
                                </sc.MessageContainer>
                            </sc.FieldsContainer>

                            <sc.CheckboxContainer>
                                {contactForm.hasCheckboxData && (
                                    <>
                                        <sc.CheckboxInputContainer>
                                            <Field id="changeCar" name="changeCar" type="checkbox" initialValue={false}>
                                                {({ input: { value, ...restInput } }) => (
                                                    <sc.CheckboxInput id="changeCar-input" value={String(value)} {...restInput} />
                                                )}
                                            </Field>
                                            <sc.CheckboxLabel htmlFor="changeCar-input">
                                                <FormattedMessage
                                                    id="leaseme.contactForm.changeCar.label"
                                                    defaultMessage="Ich würde gerne mein Auto wechseln"
                                                />
                                            </sc.CheckboxLabel>
                                        </sc.CheckboxInputContainer>

                                        <sc.CheckboxInputContainer>
                                            <Field id="financeOffer" name="financeOffer" type="checkbox" initialValue={false}>
                                                {({ input: { value, ...restInput } }) => (
                                                    <sc.CheckboxInput id="financeOffer-input" value={String(value)} {...restInput} />
                                                )}
                                            </Field>
                                            <sc.CheckboxLabel htmlFor="financeOffer-input">
                                                <FormattedMessage
                                                    id="leaseme.contactForm.financeOffer.label"
                                                    defaultMessage="Ich würde gerne ein Finanzierungsangebot zugeschickt bekommen"
                                                />
                                            </sc.CheckboxLabel>
                                        </sc.CheckboxInputContainer>
                                    </>
                                )}

                                <sc.CheckboxInputContainer>
                                    <Field id="dsgvo" name="dsgvo" type="checkbox" initialValue={false} validate={requiredValidate}>
                                        {({ input: { value, ...restInput } }) => (
                                            <sc.CheckboxInput id="dsgvo-input" value={String(value)} {...restInput} />
                                        )}
                                    </Field>
                                    <sc.CheckboxLabel htmlFor="dsgvo-input">
                                        <FormattedMessage
                                            id="leaseme.contactForm.dsgvo.label"
                                            defaultMessage="Ich habe die  <link>  Information zur Datenverarbeitung </link> zur Kenntnis genommen."
                                            values={{
                                                // eslint-disable-next-line react/display-name
                                                link: (chunks: any) => (
                                                    <sc.StyledLink target="_blank" href={privacyPolicyLinks[getConfig("scope").domain]}>
                                                        {chunks}
                                                    </sc.StyledLink>
                                                ),
                                            }}
                                        />
                                        {" *"}
                                    </sc.CheckboxLabel>
                                </sc.CheckboxInputContainer>
                            </sc.CheckboxContainer>

                            <sc.RequiredInfoContainer>
                                {"* "}
                                <FormattedMessage id="leaseme.contactForm.requiredFields" defaultMessage="Pflichtfeld. Einträge erforderlich" />
                            </sc.RequiredInfoContainer>

                            <sc.SendContainer>
                                {contactForm.hasSendInfo && (
                                    <FormattedMessage
                                        id="leaseme.contactForm.sendCopy"
                                        defaultMessage="Eine Kopie dieser Anfrage wird an ihre E-Mail-Adresse gesendet."
                                    />
                                )}
                                <sc.StyledButton
                                    variant={ButtonVariant.Primary}
                                    size={ButtonSize.Big}
                                    onClick={handleSubmit}
                                    disabled={!valid || isLoading}
                                >
                                    {isLoading ? (
                                        <sc.Loading />
                                    ) : (
                                        <FormattedMessage id="leaseme.contactForm.send" defaultMessage="Nachricht senden" />
                                    )}
                                </sc.StyledButton>
                            </sc.SendContainer>
                        </sc.StyledForm>
                    );
                }}
            />
        </sc.ContactForm>
    );
};

export default ContactForm;
