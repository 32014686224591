import { GQLFinanceType, GQLVehicleDetailQuery, GQLVehicleType } from "@src/graphql.apollo.generated";
import useSearchFilter from "@src/shared/context/searchfilter/useSearchFilter";
import FormattedCurrency from "@src/shared/utils/FormattedCurrency";
import * as React from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";

import * as sc from "./PriceInfoValues.sc";

interface IPriceInfoValuesProps {
    visibleTab: GQLFinanceType;
    data: GQLVehicleDetailQuery["vehicle"];
}

const PriceInfoValues: React.FunctionComponent<IPriceInfoValuesProps> = ({ visibleTab, data }) => {
    const { filter } = useSearchFilter();
    const intl = useIntl();

    const priceInfo = data?.finCalcData;

    const rate = priceInfo?.rate;
    const downPayment = priceInfo?.downPayment ?? 0;

    return (
        <>
            <sc.ContentValues>
                <sc.ResidualValue>
                    <sc.ResidualValueTitle>
                        {visibleTab === GQLFinanceType.leasing ? (
                            data.type === GQLVehicleType.carHub ? (
                                <FormattedMessage id="leaseme.priceInfo.residualValue.rentalLeasing" defaultMessage="Mietleasing" />
                            ) : (
                                <FormattedMessage id="leaseme.priceInfo.residualValue.leasing" defaultMessage="Restwert-Leasing" />
                            )
                        ) : (
                            <FormattedMessage id="leaseme.priceInfo.residualValue.credit" defaultMessage="Restwert-Kredit" />
                        )}
                    </sc.ResidualValueTitle>
                    <sc.AmountTextContainer>
                        <sc.Amount>
                            <FormattedCurrency value={rate} maximumFractionDigits={2} minimumFractionDigits={2} /> **
                        </sc.Amount>
                        <sc.ResidualValueText>
                            <FormattedMessage id="leaseme.perMonth" defaultMessage="pro Monat" />
                        </sc.ResidualValueText>
                    </sc.AmountTextContainer>
                </sc.ResidualValue>
                <sc.Values>
                    <sc.ValueHeader>
                        <FormattedMessage
                            id="leaseme.runtime.value"
                            defaultMessage="{financeRuntime} Monate"
                            values={{
                                financeRuntime: intl.formatNumber(filter.financeRuntime),
                            }}
                        />
                    </sc.ValueHeader>
                    {visibleTab === GQLFinanceType.leasing && (
                        <sc.ValueHeader>
                            <FormattedNumber value={filter.kmPerYear} /> <FormattedMessage id="leaseme.km" defaultMessage="km" />
                        </sc.ValueHeader>
                    )}
                    {visibleTab === GQLFinanceType.credit && (
                        <sc.ValueHeader>
                            <FormattedCurrency value={data.finCalcData?.residualValue} />
                        </sc.ValueHeader>
                    )}
                    <sc.ValueHeader>
                        <FormattedCurrency value={downPayment !== 0 ? downPayment : filter.downPayment} />
                    </sc.ValueHeader>
                    <sc.ValueContent>
                        {" "}
                        <FormattedMessage id="leaseme.runtime.label" defaultMessage="Laufzeit" />
                    </sc.ValueContent>
                    {visibleTab === GQLFinanceType.leasing && (
                        <sc.ValueContent>
                            <FormattedMessage id="leaseme.perYear" defaultMessage="pro Jahr" />
                        </sc.ValueContent>
                    )}
                    {visibleTab === GQLFinanceType.credit && (
                        <sc.ValueContent>
                            <FormattedMessage id="leaseme.remainingCredit.label" defaultMessage="Restkredit" /> ({filter.remainingCredit}%)
                        </sc.ValueContent>
                    )}
                    <sc.ValueContent>
                        <FormattedMessage id="leaseme.downPayment.label" defaultMessage="Eigenleistung" />
                    </sc.ValueContent>
                </sc.Values>
            </sc.ContentValues>
        </>
    );
};

export default PriceInfoValues;
