import styled from "styled-components";

export const Root = styled.div`
    margin: 40px 0;
    padding: 0 20px;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        padding: 0;
    }
`;

export const HorizontalLine = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.darkRed};
    width: 76px;
`;

export const DropdownHeadline = styled.div`
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    font-weight: 700;
    display: none;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 40px;
        padding: 0;
        display: block;
    }
`;

export const HeadlineText = styled.h1`
    margin-bottom: 0;
    font-size: 26px;
    line-height: 30px;
    margin-top: 19px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    ${({ theme }) => theme.breakpoints.lg.mediaquery} {
        font-size: 32px;
        line-height: 42px;
    }
`;

export const Disclaimer = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.porscheBankBlue};
    padding: 20px 0;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 16px;
    }

    a {
        color: ${({ theme }) => theme.colors.porscheBankBlue};
        padding-left: 5px;
        transition: all 0.25s ease-in-out;
        font-weight: 700;
        text-decoration: none;
    }

    a:hover {
        color: ${({ theme }) => theme.colors.porscheBankBlue};
    }
`;

export const Loading = styled.div`
    z-index: 1;
    content: "";
    position: relative;
    left: 0;
    right: 0;
    height: 30px;
    margin-right: 30px;

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        height: 23px;
        width: 23px;
        animation: rotate 0.8s infinite linear, fadeInLoading 2s linear;
        border: 2px solid ${({ theme }) => theme.colors.typo};
        border-right-color: transparent;
        border-radius: 50%;
        opacity: 0.6;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes fadeInLoading {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 0.6;
        }
    }
`;
